<template>
  <v-app @mousemove='mousemove'>
    <TopNavbar />
    <v-main>
      <v-container fluid>
        <RotateAlert/>
        <router-view />
      </v-container>
    </v-main>
    <AppFooter />
  </v-app>
</template>

<script>
import TopNavbar from './components/TopNavbar.vue';
import AppFooter from '@/components/AppFooter.vue';
import RotateAlert from '@/RotateAlert.vue';

export default {
  components: {
    TopNavbar,
    AppFooter,
    RotateAlert,
  },

};
</script>
