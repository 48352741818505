import apiClient from '@/services/apiClient';
import { AUTH_REQUEST, AUTH_ERROR, AUTH_SUCCESS, SET_TOKEN_LOCALSTORAGE, AUTH_LOGOUT } from '@/store/actions/auth';
import {
  USER_TOKEN_KEY,
  USER_ROLE_KEY,
  USER_NAME_KEY,
  USER_ID_KEY,
  SESSION_ID_KEY,
} from '@/helpers/localStorageLabels';
import {ERROR_STATUS, LOADING_STATUS, SUCCESS_STATUS} from '@/helpers/statusLabels';

const state = {
  token: localStorage.getItem(USER_TOKEN_KEY) || '',
  role: localStorage.getItem(USER_ROLE_KEY) || '',
  userName: localStorage.getItem(USER_NAME_KEY) || '',
  userId: localStorage.getItem(USER_ID_KEY) || '',
  userProfile: null,
  status: '',
  hasLoadedOnce: false,
  errors: null,
  managerId: '',
  passwordResetRequired: null,
};

const getters = {
  isAuthenticated: (state) =>
    !!(state.token && state.token !== 'undefined' && state.token !== '' && state.token !== null),
  authStatus: (state) => state.status,
  hasError: (state) => state.status === ERROR_STATUS,
  userRole: (state) => state.role,
  userName: (state) => state.userName,
  userId: (state) => state.userId, 
  errors: (state) => state.errors,
  passwordResetRequired: (state) => state.passwordResetRequired,
};

const actions = {
  [AUTH_REQUEST]: ({ commit }, user) =>
    new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);
      apiClient
        .authenticate(user)
        .then((resp) => {
          const resultData = resp.data;
          commit(AUTH_SUCCESS, resultData);
          resolve(resp);
        })
        .catch((err) => {
          commit(AUTH_ERROR, err);
          reject(err);
        });
    }),
  // eslint-disable-next-line no-unused-vars
  [AUTH_LOGOUT]: async ({ commit }) => {
    try {
      await apiClient.logout();
    } finally {
      commit(AUTH_LOGOUT);
      localStorage.clear();
      sessionStorage.clear();
      location.reload();
    }
  },
};

const mutations = {
  [SET_TOKEN_LOCALSTORAGE]: (state) => {
    state.token = localStorage.getItem(USER_TOKEN_KEY);
  },
  [AUTH_REQUEST]: (state) => {
    state.status = LOADING_STATUS;
  },
  [AUTH_SUCCESS]: (state, resp) => {

    localStorage.setItem(SESSION_ID_KEY, resp.session._id);
    localStorage.setItem(USER_TOKEN_KEY, resp.session.token);
    localStorage.setItem(USER_ROLE_KEY, resp.session.user.Role);
    localStorage.setItem(USER_NAME_KEY, resp.session.user.Username);
    localStorage.setItem(USER_ID_KEY, resp.session.user.ID);
    apiClient.setToken(resp.session.token);
    
    state.status = SUCCESS_STATUS;
    state.userId = resp.session.id;
    state.token = resp.session.token;
    state.role = resp.session.user.Role;
    state.userName = resp.session.user.Username;
    state.hasLoadedOnce = true;
    state.passwordResetRequired = false;

  },
  [AUTH_ERROR]: (state, err) => {
    state.status = ERROR_STATUS;
    state.errors = err.message;
    state.hasLoadedOnce = true;
  },
  [AUTH_LOGOUT]: (state) => {
    state.userProfile = null;
    state.token = '';
    state.role = '';
    state.userName = '';
    state.status = '';
    state.errors = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
