export const USER_TOKEN_KEY = 'user-token';
export const USER_ROLE_KEY = 'user-role';
export const USER_NAME_KEY = 'user-name';
export const USER_ID_KEY = 'user-id';
export const SESSION_ID_KEY = 'sessionId';

export default {
    USER_TOKEN_KEY,
    USER_ROLE_KEY,
    USER_NAME_KEY,
    USER_ID_KEY,
    SESSION_ID_KEY,
};