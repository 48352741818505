<template>
    <v-navigation-drawer
        v-model='drawerOpen'
        class='elevation-1'
        left>
            <v-list nav>

                <template v-for='item in filteredItems'>
                    <v-list-item
                        v-if='item.type === "single"'
                        :key='item.name'
                        :prepend-icon='item.icon'
                        :value='item.path'
                        @click='routeTo(item.path)'
                    >
                        <div>
                            {{item.name}}
                        </div>
                    </v-list-item>

                    <v-list-group
                        :value='item.path'
                        v-else-if='item.type === "multi"'
                        :key='item.icon'
                        :prepend-icon='item.icon'
                    >
                        <template v-slot:activator='{ props }'>
                            <v-list-item
                            v-bind='props'
                            >
                                <div>
                                    {{item.name}}
                                </div>
                            </v-list-item>
                        </template>

                        <v-list-item
                            v-for='child in item.children'
                            :key='child.name'
                            :prepend-icon='child.icon'
                            :value='child.path'
                            @click='routeTo(child.path)'
                            style='padding-left: 30px !important'
                        >
                            <div>
                                {{child.name}}
                            </div>
                        </v-list-item>
                    </v-list-group>
                </template>
                    
        </v-list>
        <template v-slot:append>
            <div class='pa-2'>
                <v-btn block color='primary' @click='doLogout'>
                Logout
                </v-btn>
            </div>
        </template>
    </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { AUTH_LOGOUT } from '@/store/actions/auth';

export default {
    props: {
        isDrawerOpen: {
            type: Boolean,
        },
    },
    data() {
        return {
            drawerOpen: false,
            items: [
                {
                    name: 'Home Page',
                    icon: 'mdi-home',
                    path: '/',
                    type: 'single',
                },
                {
                    name: 'Batch Compare',
                    icon: 'mdi-file-upload-outline',
                    path: '/batch',
                    type: 'single',
                },
                {
                    name: 'Manual Search By Name',
                    icon: 'mdi-format-list-bulleted',
                    path: '/search-by-name',
                    type: 'single',
                },
                {
                    name: 'Manual Search By LEI',
                    icon: 'mdi-format-list-bulleted',
                    path: '/search-by-lei',
                    type: 'single',
                },
                {
                    name: 'Manual Name Compare',
                    icon: 'mdi-format-list-bulleted',
                    path: '/name-compare',
                    type: 'single',
                },
                {
                    name: 'Querylog',
                    icon: 'mdi-format-list-bulleted',
                    path: '/querylog',
                    type: 'single',
                },
                {
                    name: 'Settings',
                    icon: 'mdi-format-list-bulleted',
                    path: '/settings',
                    type: 'multi',
                    onlyAdmin: true,
                    children: [
                        {
                            name: 'Thresholds Management',
                            icon: 'mdi-format-list-bulleted',
                            path: '/thresholds',
                        },{
                            name: 'Users Management',
                            icon: 'mdi-format-list-bulleted',
                            path: '/users',
                        },
                    ],

                },
            ],
        };
    },
    computed: {
        ...mapGetters(['userRole']),
        isAuthenticated(){
            return this.$store.getters.isAuthenticated;
        },

        filteredItems() {
            return this.items.filter(item => !item.onlyAdmin || this.userRole == 'admin');
        },

    },
    watch: {
        isDrawerOpen(value) {
            this.drawerOpen = value;
        },
        drawerOpen(value) {
            this.$emit('updateToggle', value); 
        },
    },

    methods: {
        ...mapActions({ logout: AUTH_LOGOUT }),
        
        routeTo(page) {
            const currentPath = this.$router.currentRoute.value.name;
            if (currentPath !== page) {
                this.$router.push({ path: page });
            }
        },
        async doLogout() {
            await this.logout();
            this.$router.go({ name: 'login' });
        },
        
    },
};
</script>

<style>
    .v-list-item--active {
        background: #161a4f !important;
        color: #fff !important;
    }

    .v-list-group--open > div:first-child {
      background-color: #dedede !important;
      color: black !important;
    }
</style>
